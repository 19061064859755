import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import router from '@/router';
import { formatProductItem } from '@/helpers/product';
import { getFormError } from '@/helpers/form';

// Components
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseButton, BaseSpacer, BaseText } from '@/components/partials';
import { ProductCard } from '@/components/features';
import { BaseForm, BaseError } from '@/components/partials/forms';
import { Modal } from '@/components/containers';

// types
import type { ComputedRef, Ref } from 'vue';
import type { WopsProductInterface } from '@/classes/product';
import type { TransitionInWarehouseRequestInterface } from '@/store/types/product';
import type { WopsProductVariantInterface } from '@/classes/productVariant';


export default /*@__PURE__*/_defineComponent({
  __name: '_toy',
  setup(__props) {

const store = useStore();
const route = useRoute();

const modalStatus: Ref<string | null> = ref(null);
const getProduct: Promise<void> = async () => store.dispatch('product/getProduct', route.params.product_id);
const transitionInWarehouse: Promise<void> = async () =>
  await store.dispatch('product/transitionInWarehouse', transitionInWarehouseRequest.value);

// @Todo: Change path to fetch errors from page store module
const errors: ComputedRef<Record<string, string[]>> = computed(() => store.getters['product/getStoreErrors']);
const clearErrors: void = () => store.dispatch('product/clearErrors');

const product: ComputedRef<WopsProductInterface> = computed(() =>
  formatProductItem(store.state.product.product)
);
const productVariant: ComputedRef<WopsProductVariantInterface> = computed(
  () => store.getters['product/getProductVariant']
);
const selectedBinName: ComputedRef<string> = computed(() => store.getters['bin/getSelectedBin']);
const transitionInWarehouseRequest: ComputedRef<TransitionInWarehouseRequestInterface> = computed(() => ({
  productVariantId: productVariant.value?.id,
  binName: selectedBinName.value,
}));
const canTransitionStatus: ComputedRef<boolean> = computed(() => {
  return (
    !!transitionInWarehouseRequest.value.productVariantId && !!transitionInWarehouseRequest.value.binName
  );
});

const goToBinSelectPage: void = async () =>
  await router.push({ name: 'Stock - Select Bin', params: { product_id: product.value.id } });

onMounted(() => {
  if (!product.value?.id) getProduct();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(ProductCard), { product: product.value }, null, 8, ["product"]),
      _createVNode(_unref(BaseSpacer), { size: "1" }),
      (canTransitionStatus.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_unref(Modal), {
              title: "Confirm changes",
              "open-button-text": "Add 1 unit back into stock",
              color: "default",
              "onModal:close": clearErrors
            }, {
              default: _withCtx(() => [
                (errors.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_unref(BaseError), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(getFormError)(errors.value)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(BaseSpacer), { size: "1" })
                    ], 64))
                  : _createCommentVNode("", true),
                _createVNode(_unref(BaseForm), { onFormSubmitted: transitionInWarehouse }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BaseText), { tag: "p" }, {
                      default: _withCtx(() => [
                        _cache[0] || (_cache[0] = _createTextVNode(" Are you sure you want to add 1 ")),
                        _createVNode(_unref(BaseText), { tag: "span" }, {
                          default: _withCtx(() => [
                            _createElementVNode("b", null, _toDisplayString(product.value.name), 1)
                          ]),
                          _: 1
                        }),
                        _cache[1] || (_cache[1] = _createTextVNode(" into bin: ")),
                        _createVNode(_unref(BaseText), { tag: "span" }, {
                          default: _withCtx(() => [
                            _createElementVNode("b", null, _toDisplayString(selectedBinName.value) + "?", 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(BaseSpacer), { size: "1" }),
                    _createVNode(_unref(BaseButton), {
                      class: "btn-confirm-transition",
                      color: "success",
                      type: "submit"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createElementVNode("span", null, "Confirm", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseSpacer), { size: "1" })
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                class: "btn-select-bin",
                color: "default",
                size: "large",
                type: "button",
                onClick: goToBinSelectPage
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("span", null, "Select Bin", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})